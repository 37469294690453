import {
  HomePage,
  DashboardPage,
  ProfilePage,
  UserPage,
  UserNewPage,
  DevicePage,
  GlobalDevicePage,
  GlobalAccountPage,
  CoaDevicePage,
  CoaCartridgePage,
  CoaDeviceDataPage,
  CalibrationBackupPage,
  DownloadPage,
  DeviceNewPage,
  CellPage,
  CellNewPage,
  MatPage,
  MatNewPage,
  ProtocolPage,
  ProtocolNewPage,
  ProjectPage,
  ProjectNewPage,
  TxSamplePage,
  DataViewerPage,
  TxReportPage,
  FacsPage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/dashboard",
    element: DashboardPage,
  },
  {
    path: "/profile",
    element: ProfilePage,
  },
  {
    path: "/user",
    element: UserPage,
  },
  {
    path: "/user/new",
    element: UserNewPage,
  },

  // Device Monitoring
  {
    path: "/device",
    element: DevicePage,
  },
  {
    path: "/device/new",
    element: DeviceNewPage,
  },
  {
    path: "/global/device",
    element: GlobalDevicePage,
  },
  {
    path: "/global/account",
    element: GlobalAccountPage,
  },
  {
    path: "/coa/device",
    element: CoaDevicePage,
  },
  {
    path: "/coa/cartridge",
    element: CoaCartridgePage,
  },
  {
    path: "/coa/device/data",
    element: CoaDeviceDataPage,
  },
  {
    path: "/device/calibrationBackup",
    element: CalibrationBackupPage,
  },
  {
    path: "/download",
    element: DownloadPage,
  },
  // Project Management
  {
    path: "/cell",
    element: CellPage,
  },
  {
    path: "/cell/new",
    element: CellNewPage,
  },
  {
    path: "/mat",
    element: MatPage,
  },
  {
    path: "/mat/new",
    element: MatNewPage,
  },
  {
    path: "/protocol",
    element: ProtocolPage,
  },
  {
    path: "/protocol/new",
    element: ProtocolNewPage,
  },
  {
    path: "/project",
    element: ProjectPage,
  },
  {
    path: "/project/new",
    element: ProjectNewPage,
  },
  // Data Analysis
  {
    path: "/txSample",
    element: TxSamplePage,
  },
  {
    path: "/dataViewer",
    element: DataViewerPage,
  },
  {
    path: "/txReport",
    element: TxReportPage,
  },
  {
    path: "/facs",
    element: FacsPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
