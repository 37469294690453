import "./txReport.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCommon } from "../../contexts/common";
// api
import { test, queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Tick,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export as ExportChart,
  Legend,
  Margin,
  Label,
  Format,
  Crosshair,
  Tooltip,
  Grid,
} from "devextreme-react/chart";

export default function TxReport() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const commonContext = useCommon();

  const [userId, setUserId] = useState("");

  const [txId, setTxId] = useState("");

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [summaryJsonDataSource, setSummaryJsonDataSource] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      setTxId(commonContext.str1);
      search(commonContext.str1);
    }
  }, []);

  function search(txId: string) {
    let query = "SELECT * FROM sample";
    query += ` WHERE txId = '${txId}'`;
    queryJsonArray("/tx/querysample", query).then((sampleJsonData: Array<Object>) => {
      if (sampleJsonData.length !== 1) {
        alert("No data!", "Error");
        return;
      }
      let oneRowJson: Object | null = new Object();

      // Summary
      let txId: string = U.stringValueFromJson(sampleJsonData[0], "txId");
      let txTime: Date = U.dateValueFromJson(sampleJsonData[0], "txTime");
      let project: string = U.stringValueFromJson(sampleJsonData[0], "project");
      let note: string = U.stringValueFromJson(sampleJsonData[0], "note");
      let evaluation: string = U.stringValueFromJson(sampleJsonData[0], "evaluation");
      let electricalEvaluation: string = "Error";
      if (evaluation[0] === "P") electricalEvaluation = "Pass";
      if (evaluation[0] === "W") electricalEvaluation = "Warning";
      if (evaluation[0] === "F") electricalEvaluation = "Fail";
      let fluidicEvaluation: string = "Error";
      if (evaluation[1] === "P") fluidicEvaluation = "Pass";
      if (evaluation[1] === "W") fluidicEvaluation = "Warning";
      if (evaluation[1] === "F") fluidicEvaluation = "Fail";
      let thermalEvaluation: string = "Error";
      if (evaluation[2] === "P") thermalEvaluation = "Pass";
      if (evaluation[2] === "W") thermalEvaluation = "Warning";
      if (evaluation[2] === "F") thermalEvaluation = "Fail";
      let deviceModel: string = U.stringValueFromJson(sampleJsonData[0], "deviceModel");
      let cartridge: string = U.stringValueFromJson(sampleJsonData[0], "cartridge");
      let cartridgeLot: string = U.stringValueFromJson(sampleJsonData[0], "cartridgeLot");
      let txUserId: string = U.stringValueFromJson(sampleJsonData[0], "txUserId");
      let matPumpSlip: number = U.floatValueFromJson(sampleJsonData[0], "matPumpSlip");
      let cellPumpSlip: number = U.floatValueFromJson(sampleJsonData[0], "cellPumpSlip");
      let deltaV: number = U.floatValueFromJson(sampleJsonData[0], "deltaV");
      let protocol: string = U.stringValueFromJson(sampleJsonData[0], "protocol");
      let freq: number = U.floatValueFromJson(sampleJsonData[0], "freq");
      let voltage: number = U.floatValueFromJson(sampleJsonData[0], "voltage");
      let duty: number = U.floatValueFromJson(sampleJsonData[0], "duty");
      let current: number = U.floatValueFromJson(sampleJsonData[0], "current");
      let power: number = U.floatValueFromJson(sampleJsonData[0], "power");
      let coolerMode: number = U.intValueFromJson(sampleJsonData[0], "coolerMode");
      let coolerT: number = U.floatValueFromJson(sampleJsonData[0], "coolerT");
      let coolingPower: number = U.floatValueFromJson(sampleJsonData[0], "coolingPower");
      let outflow: number = U.floatValueFromJson(sampleJsonData[0], "outflow");
      let mixingRatio: number = U.floatValueFromJson(sampleJsonData[0], "mixingRatio");
      let cooler: number = U.floatValueFromJson(sampleJsonData[0], "cooler");
      let harvestVolume: number = U.floatValueFromJson(sampleJsonData[0], "harvestVolume");
      let runningTime: number = U.floatValueFromJson(sampleJsonData[0], "runningTime");
      let cellName: string = U.stringValueFromJson(sampleJsonData[0], "cellName");
      let cellBuffer: string = U.stringValueFromJson(sampleJsonData[0], "cellBuffer");
      let cellConc: number = U.floatValueFromJson(sampleJsonData[0], "cellConc");
      let matName: string = U.stringValueFromJson(sampleJsonData[0], "matName");
      let matBuffer: string = U.stringValueFromJson(sampleJsonData[0], "matBuffer");
      let matConc: number = U.floatValueFromJson(sampleJsonData[0], "matConc");

      let summaryJsonData = new Array<Object>();
      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "TX ID");
      oneRowJson = U.addStringToJson(oneRowJson, "value", txId);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "TX Time");
      oneRowJson = U.addStringToJson(oneRowJson, "value", txTime.toLocaleDateString() + ", " + txTime.toLocaleTimeString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Project");
      oneRowJson = U.addStringToJson(oneRowJson, "value", project);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Protocol");
      oneRowJson = U.addStringToJson(oneRowJson, "value", protocol);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Note");
      oneRowJson = U.addStringToJson(oneRowJson, "value", note);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Electrical Evaluation");
      oneRowJson = U.addStringToJson(oneRowJson, "value", electricalEvaluation);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Fluidic Evaluation");
      oneRowJson = U.addStringToJson(oneRowJson, "value", fluidicEvaluation);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Thermal Evaluation");
      oneRowJson = U.addStringToJson(oneRowJson, "value", thermalEvaluation);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat Pump Slip (mm)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matPumpSlip.toFixed(2));
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Pump Slip (mm)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellPumpSlip.toFixed(2));
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Delta V (V)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", deltaV.toFixed(1));
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Device Model");
      oneRowJson = U.addStringToJson(oneRowJson, "value", deviceModel);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cartridge");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cartridge);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cartridge Lot");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cartridgeLot);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "TX User ID");
      oneRowJson = U.addStringToJson(oneRowJson, "value", txUserId);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Frequency (kHz)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", freq.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Voltage (V)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", voltage.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Current (mA)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", current.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Duty (%)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", duty.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Power (W)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", power.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cooler Mode");
      oneRowJson = U.addStringToJson(oneRowJson, "value", coolerMode.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cooler T (C)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", coolerT.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cooling Power (W)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", coolingPower.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Outflow (mL/h)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", outflow.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mixing Ratio(M/C)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", mixingRatio.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Harvest Volume (mL)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", harvestVolume.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Running Time (min)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", runningTime.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Name");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellName);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Buffer");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellBuffer);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Conc. (10^6 cells/mL)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellConc.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat. Name");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matName);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat. Buffer");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matBuffer);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat. Conc. (ug/mL)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matConc.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      setSummaryJsonDataSource(summaryJsonData);

      // Chart Data
      // data1: current (mA), data2: T_cold (C), data3: T_air (C), data4: none
      // data5: T_ir (C), data6: matStageForce, data7: cellStageForce, data8: measuredV (V)
      let dt1: number = U.floatValueFromJson(sampleJsonData[0], "dt1");
      let dt2: number = U.floatValueFromJson(sampleJsonData[0], "dt2");
      let dt3: number = U.floatValueFromJson(sampleJsonData[0], "dt3");
      let dt4: number = U.floatValueFromJson(sampleJsonData[0], "dt4");
      let dt5: number = U.floatValueFromJson(sampleJsonData[0], "dt5");
      let dt6: number = U.floatValueFromJson(sampleJsonData[0], "dt6");
      let dt7: number = U.floatValueFromJson(sampleJsonData[0], "dt7");
      let dt8: number = U.floatValueFromJson(sampleJsonData[0], "dt7");
      let data1: string = U.stringValueFromJson(sampleJsonData[0], "data1");
      let data2: string = U.stringValueFromJson(sampleJsonData[0], "data2");
      let data3: string = U.stringValueFromJson(sampleJsonData[0], "data3");
      let data4: string = U.stringValueFromJson(sampleJsonData[0], "data4");
      let data5: string = U.stringValueFromJson(sampleJsonData[0], "data5");
      let data6: string = U.stringValueFromJson(sampleJsonData[0], "data6");
      let data7: string = U.stringValueFromJson(sampleJsonData[0], "data7");
      let data8: string = U.stringValueFromJson(sampleJsonData[0], "data8");
      let data1Array = U.getNumberArrayFromNumberArrayString(data1);
      let data2Array = U.getNumberArrayFromNumberArrayString(data2);
      let data3Array = U.getNumberArrayFromNumberArrayString(data3);
      let data4Array = U.getNumberArrayFromNumberArrayString(data4);
      let data5Array = U.getNumberArrayFromNumberArrayString(data5);
      let data6Array = U.getNumberArrayFromNumberArrayString(data6);
      let data7Array = U.getNumberArrayFromNumberArrayString(data7);
      let data8Array = U.getNumberArrayFromNumberArrayString(data8);
      if (!data1Array || !data2Array || !data3Array || !data5Array || !data6Array || !data7Array) {
        alert("No chart data!", "Error");
        return;
      }
      let data1N: number = data1Array.length;
      let data2N: number = data2Array.length;
      let data3N: number = data3Array.length;
      let data4N: number = 0;
      if (data4Array) data4N = data4Array.length;
      let data5N: number = data5Array.length;
      let data6N: number = data6Array.length;
      let data7N: number = data7Array.length;
      let data8N: number = 0;
      if (data8Array) data8N = data8Array.length;
      let dataMaxN: number = data1N;
      if (dataMaxN < data2N) dataMaxN = data2N;
      if (dataMaxN < data3N) dataMaxN = data3N;
      if (dataMaxN < data4N) dataMaxN = data4N;
      if (dataMaxN < data5N) dataMaxN = data5N;
      if (dataMaxN < data6N) dataMaxN = data6N;
      if (dataMaxN < data7N) dataMaxN = data7N;
      if (dataMaxN < data8N) dataMaxN = data8N;
      if (dataMaxN === 0) {
        alert("No chart data!", "Error");
        return;
      }

      // Chart Data 생성
      let jsonData = new Array<Object>();
      //let index: number = 0;
      for (let i = 0; i < dataMaxN; i++) {
        oneRowJson = new Object();

        if (i < data1N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x1", (i * dt1) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y1", data1Array[i]);
        }

        if (i < data2N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x2", (i * dt2) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y2", data2Array[i]);
        }

        if (i < data3N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x3", (i * dt3) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y3", data3Array[i]);
        }

        if (data4Array) {
          if (i < data4N) {
            oneRowJson = U.addNumberToJson(oneRowJson, "x4", (i * dt4) / 60.0);
            oneRowJson = U.addNumberToJson(oneRowJson, "y4", data4Array[i]);
          }
        }

        if (i < data5N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x5", (i * dt5) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y5", data5Array[i]);
        }

        if (i < data6N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x6", (i * dt6) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y6", data6Array[i]);
        }

        if (i < data7N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x7", (i * dt7) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y7", data7Array[i]);
        }

        if (data8Array) {
          if (i < data8N) {
            oneRowJson = U.addNumberToJson(oneRowJson, "x8", (i * dt8) / 60.0);
            oneRowJson = U.addNumberToJson(oneRowJson, "y8", data8Array[i]);
          }
        }

        if (oneRowJson !== null) jsonData.push(oneRowJson);
      }
      setJsonDataSource(jsonData);
    });
  }

  function onDataGridSelectionChanged(e: any) {}

  return (
    <React.Fragment>
      <h2 className={"content-block"}>TX Report</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div>
              <DataGrid dataSource={summaryJsonDataSource} columnAutoWidth={true} allowColumnReordering={true} showColumnHeaders={false}>
                <Paging defaultPageSize={40} />
                <LoadPanel enabled />
                <Selection mode="single" />
                <Column dataField="title" caption="Title" />
                <Column dataField="value" caption="Value" />
              </DataGrid>
            </div>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Current"}>
              <CommonSeriesSettings argumentField="x1" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y1"} name={"Current (mA)"} />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Current (mA)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Measured Voltage"}>
              <CommonSeriesSettings argumentField="x8" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y8"} name={"Measured Voltage (V)"} color="#6D9CC6" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Voltage (V)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Temperature"}>
              <CommonSeriesSettings argumentField="x2" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series argumentField={"x2"} valueField={"y2"} name={"T_cold"} color="#6D9CC6" />
              <Series argumentField={"x3"} valueField={"y3"} name={"T_hot"} color="#B273CE" />
              <Series argumentField={"x5"} valueField={"y5"} name={"T_ir"} color="#FF0000" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Temperature (C)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Syringe Pressure"}>
              <CommonSeriesSettings argumentField="x6" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series argumentField={"x6"} valueField={"y6"} name={"Mat."} color="#6D9CC6" />
              <Series argumentField={"x7"} valueField={"y7"} name={"Cell"} color="#FF0000" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Push Force (kgf)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
