import "./dataViewer.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCommon } from "../../contexts/common";
// api
import { test, queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { FileUploader } from "devextreme-react/file-uploader";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Tick,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export as ExportChart,
  Legend,
  Margin,
  Label,
  Format,
  Crosshair,
  Tooltip,
  Grid,
} from "devextreme-react/chart";

export default function DataViewer() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const commonContext = useCommon();

  const [userId, setUserId] = useState("");

  const [txId, setTxId] = useState("");

  const [chartJsonDataSource, setChartJsonDataSource] = useState<Array<Object>>();
  const [summaryJsonDataSource, setSummaryJsonDataSource] = useState<Array<Object>>();
  const [note, setNote] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;

      //setTxId(commonContext.str1);
      //search(commonContext.str1);
    }
  }, []);

  const handleFileUpload = (e: any) => {
    const file = e.value[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target !== null) {
          if (event.target.result !== null) {
            onFileUploaded(event.target.result as string);
          }
        }
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid .dat file", "Error");
    }
  };

  function onFileUploaded(str: string) {
    let json: Object = new Object();
    let success: boolean = false;
    try {
      json = JSON.parse(str);
      success = true;
    } catch (err) {}
    if (!success) return;

    let oneRowJson: Object | null = new Object();

    // Summary
    let name: string = U.stringValueFromJson(json, "name");
    let time: string = U.stringValueFromJson(json, "time");
    let swVersion: string = U.stringValueFromJson(json, "swVersion");
    let cellVol: string = U.stringValueFromJson(json, "cellVol");
    let matVol: string = U.stringValueFromJson(json, "matVol");
    let harvestVolume: number = U.floatValueFromJson(json, "harvestVolume");
    let outflow: number = U.floatValueFromJson(json, "outflow");
    let runningTime: string = U.stringValueFromJson(json, "runningTime");
    let coolerT: number = U.floatValueFromJson(json, "coolerT");
    let coolingPower: number = U.floatValueFromJson(json, "coolingPower");
    let voltage: number = U.floatValueFromJson(json, "voltage");
    let current: number = U.floatValueFromJson(json, "current");
    let energy: number = U.floatValueFromJson(json, "energy");
    let exposure: number = U.floatValueFromJson(json, "exposure");
    let tPulse: number = U.floatValueFromJson(json, "tPulse");
    let duty: number = U.floatValueFromJson(json, "duty");
    let freq: number = U.floatValueFromJson(json, "freq");
    let power: number = U.floatValueFromJson(json, "power");
    let deltaV: number = U.floatValueFromJson(json, "deltaV");
    let deviceSerial: string = U.stringValueFromJson(json, "deviceSerial");
    let deviceModel: string = U.stringValueFromJson(json, "deviceModel");
    let deviceAmpType: string = U.stringValueFromJson(json, "deviceAmpType");
    let cartridge: string = U.stringValueFromJson(json, "cartridge");
    let cartridgeLot: string = U.stringValueFromJson(json, "cartridgeLot");
    let evaluation: string = U.stringValueFromJson(json, "evaluation");
    let matPumpSlip: number = U.floatValueFromJson(json, "matPumpSlip");
    let cellPumpSlip: number = U.floatValueFromJson(json, "cellPumpSlip");
    let humidity: number = U.floatValueFromJson(json, "humidity");
    let mainBoardT: number = U.floatValueFromJson(json, "mainBoardT");
    let electricalEvaluation: string = "Error";
    if (evaluation.length >= 1) {
      if (evaluation[0] === "P") electricalEvaluation = "Pass";
      if (evaluation[0] === "W") electricalEvaluation = "Warning";
      if (evaluation[0] === "F") electricalEvaluation = "Fail";
    }

    let fluidicEvaluation: string = "Error";
    if (evaluation.length >= 2) {
      if (evaluation[1] === "P") fluidicEvaluation = "Pass";
      if (evaluation[1] === "W") fluidicEvaluation = "Warning";
      if (evaluation[1] === "F") fluidicEvaluation = "Fail";
    }
    let thermalEvaluation: string = "Error";
    if (evaluation.length >= 3) {
      if (evaluation[2] === "P") thermalEvaluation = "Pass";
      if (evaluation[2] === "W") thermalEvaluation = "Warning";
      if (evaluation[2] === "F") thermalEvaluation = "Fail";
    }

    let summaryJsonData = new Array<Object>();
    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Name");
    oneRowJson = U.addStringToJson(oneRowJson, "value", name);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Time");
    oneRowJson = U.addStringToJson(oneRowJson, "value", time);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Serial");
    oneRowJson = U.addStringToJson(oneRowJson, "value", deviceSerial);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "SW Version");
    oneRowJson = U.addStringToJson(oneRowJson, "value", swVersion);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Vol. (mL)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", cellVol);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat Vol. (mL)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", matVol);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Harvest Vol. (mL)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", harvestVolume.toFixed(0));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Outflow (mL/h)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", outflow.toFixed(0));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Running Time (min)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", runningTime.toString());
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Cooler T (C)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", coolerT.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Cooling Power (W)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", coolingPower.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Voltage (V)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", voltage.toFixed(0));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Current (mA)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", current.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Energy (uJ)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", energy.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Exposure (ms)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", exposure.toFixed(0));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "T_Pulse (us)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", tPulse.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Duty (%)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", duty.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Frequency (kHz)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", freq.toFixed(0));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Power (W)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", power.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Delta V (V)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", deltaV.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Device Model");
    oneRowJson = U.addStringToJson(oneRowJson, "value", deviceModel);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Amp Type");
    oneRowJson = U.addStringToJson(oneRowJson, "value", deviceAmpType);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Cartridge");
    oneRowJson = U.addStringToJson(oneRowJson, "value", cartridge);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Cartridge Lot");
    oneRowJson = U.addStringToJson(oneRowJson, "value", cartridgeLot);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Evaluation");
    oneRowJson = U.addStringToJson(oneRowJson, "value", evaluation);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Electrical Evaluation");
    oneRowJson = U.addStringToJson(oneRowJson, "value", electricalEvaluation);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Fluidic Evaluation");
    oneRowJson = U.addStringToJson(oneRowJson, "value", fluidicEvaluation);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Thermal Evaluation");
    oneRowJson = U.addStringToJson(oneRowJson, "value", thermalEvaluation);
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat Pump Slip (mm)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", matPumpSlip.toFixed(2));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Pump Slip (mm)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", cellPumpSlip.toFixed(2));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "Humidity (%RH)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", humidity.toFixed(0));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    oneRowJson = new Object();
    oneRowJson = U.addStringToJson(oneRowJson, "title", "MainBoard T (C)");
    oneRowJson = U.addStringToJson(oneRowJson, "value", mainBoardT.toFixed(1));
    if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

    setSummaryJsonDataSource(summaryJsonData);

    // Chart Data
    // data1: current (mA), data2: T_cold (C), data3: T_air (C), data4: none (C), data5: T_ir (C), data6: matStageForce, data7: cellStageForce, data8: measuredV (V)
    let dt1: number = U.floatValueFromJson(json, "dt1");
    let dt2: number = U.floatValueFromJson(json, "dt2");
    let dt3: number = U.floatValueFromJson(json, "dt3");
    //let dt4: number = U.floatValueFromJson(json, "dt4");  // data4: none (기존에는 T_mb 였음)
    let dt5: number = U.floatValueFromJson(json, "dt5");
    let dt6: number = U.floatValueFromJson(json, "dt6");
    let dt7: number = U.floatValueFromJson(json, "dt7");
    let dt8: number = U.floatValueFromJson(json, "dt8");
    let data1: string = U.stringValueFromJson(json, "data1");
    let data2: string = U.stringValueFromJson(json, "data2");
    let data3: string = U.stringValueFromJson(json, "data3");
    //let data4: string = U.stringValueFromJson(json, "data4");
    let data5: string = U.stringValueFromJson(json, "data5");
    let data6: string = U.stringValueFromJson(json, "data6");
    let data7: string = U.stringValueFromJson(json, "data7");
    let data8: string = U.stringValueFromJson(json, "data8");
    let data1Array = U.getNumberArrayFromNumberArrayString(data1);
    let data2Array = U.getNumberArrayFromNumberArrayString(data2);
    let data3Array = U.getNumberArrayFromNumberArrayString(data3);
    //let data4Array = U.getNumberArrayFromNumberArrayString(data4);
    let data5Array = U.getNumberArrayFromNumberArrayString(data5);
    let data6Array = U.getNumberArrayFromNumberArrayString(data6);
    let data7Array = U.getNumberArrayFromNumberArrayString(data7);
    let data8Array = U.getNumberArrayFromNumberArrayString(data8);
    if (!data1Array || !data2Array || !data3Array || !data5Array || !data6Array || !data7Array) {
      alert("No chart data!", "Error");
      return;
    }
    let data1N: number = data1Array.length;
    let data2N: number = data2Array.length;
    let data3N: number = data3Array.length;
    //let data4N: number = data4Array.length;
    let data5N: number = data5Array.length;
    let data6N: number = data6Array.length;
    let data7N: number = data7Array.length;
    let data8N: number = 0;
    if (data8Array) data8N = data8Array.length;
    let dataMaxN: number = data1N;
    if (dataMaxN < data2N) dataMaxN = data2N;
    if (dataMaxN < data3N) dataMaxN = data3N;
    //if (dataMaxN < data4N) dataMaxN = data4N;
    if (dataMaxN < data5N) dataMaxN = data5N;
    if (dataMaxN < data6N) dataMaxN = data6N;
    if (dataMaxN < data7N) dataMaxN = data7N;
    if (dataMaxN < data8N) dataMaxN = data8N;
    if (dataMaxN === 0) {
      alert("No chart data-2!", "Error");
      return;
    }

    // Chart Data 생성
    let jsonData = new Array<Object>();
    //let index: number = 0;
    for (let i = 0; i < dataMaxN; i++) {
      oneRowJson = new Object();

      if (i < data1N) {
        oneRowJson = U.addNumberToJson(oneRowJson, "x1", (i * dt1) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y1", data1Array[i]);
      }

      if (i < data2N) {
        oneRowJson = U.addNumberToJson(oneRowJson, "x2", (i * dt2) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y2", data2Array[i]);
      }

      if (i < data3N) {
        oneRowJson = U.addNumberToJson(oneRowJson, "x3", (i * dt3) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y3", data3Array[i]);
      }

      //if (i < data4N) {
      //oneRowJson = U.addNumberToJson(oneRowJson, "x4", (i * dt4) / 60.0);
      //oneRowJson = U.addNumberToJson(oneRowJson, "y4", data4Array[i]);
      //}

      if (i < data5N) {
        oneRowJson = U.addNumberToJson(oneRowJson, "x5", (i * dt5) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y5", data5Array[i]);
      }

      if (i < data6N) {
        oneRowJson = U.addNumberToJson(oneRowJson, "x6", (i * dt6) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y6", data6Array[i]);
      }

      if (i < data7N) {
        oneRowJson = U.addNumberToJson(oneRowJson, "x7", (i * dt7) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y7", data7Array[i]);
      }

      if (data8Array) {
        if (i < data8N) {
          oneRowJson = U.addNumberToJson(oneRowJson, "x8", (i * dt8) / 60.0);
          oneRowJson = U.addNumberToJson(oneRowJson, "y8", data8Array[i]);
        }
      }

      if (oneRowJson !== null) jsonData.push(oneRowJson);
    }
    setChartJsonDataSource(jsonData);
  }

  function onDataGridSelectionChanged(e: any) {}

  return (
    <React.Fragment>
      <h2 className={"content-block"}>RUO Data Viewer</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="p-4 max-w-md mx-auto">
            <h2 className="text-xl font-semibold mb-4">Select a .txt File</h2>
            <FileUploader
              selectButtonText="Select File"
              labelText="Drop a .txt file here or click to select."
              accept=".txt"
              uploadMode="useForm"
              onValueChanged={handleFileUpload}
            />
          </div>

          <div className={"flex-containerH"}>
            <div>
              <DataGrid dataSource={summaryJsonDataSource} columnAutoWidth={true} allowColumnReordering={true} showColumnHeaders={false}>
                <Paging defaultPageSize={40} />
                <LoadPanel enabled />
                <Selection mode="single" />
                <Column dataField="title" caption="Title" />
                <Column dataField="value" caption="Value" />
              </DataGrid>
            </div>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={chartJsonDataSource} title={"Current"}>
              <CommonSeriesSettings argumentField="x1" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y1"} name={"Current (mA)"} />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Current (mA)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={chartJsonDataSource} title={"Measured Voltage"}>
              <CommonSeriesSettings argumentField="x8" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y8"} name={"Measured Voltage (V)"} color="#6D9CC6" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Voltage (V)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={chartJsonDataSource} title={"Temperature"}>
              <CommonSeriesSettings argumentField="x2" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series argumentField={"x2"} valueField={"y2"} name={"T_cold"} color="#6D9CC6" />
              <Series argumentField={"x3"} valueField={"y3"} name={"T_air"} color="#B273CE" />
              <Series argumentField={"x5"} valueField={"y5"} name={"T_ir"} color="#FF0000" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Temperature (C)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={chartJsonDataSource} title={"Syringe Pressure"}>
              <CommonSeriesSettings argumentField="x6" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series argumentField={"x6"} valueField={"y6"} name={"Mat."} color="#6D9CC6" />
              <Series argumentField={"x7"} valueField={"y7"} name={"Cell"} color="#FF0000" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Push Force (kgf)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#,##0.###"}></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
